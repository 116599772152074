import React from 'react';
import { doc, deleteDoc } from 'firebase/firestore';
import { db } from '../Firebase/InitialiseFirebase';
import { Recipe } from '../../../interfaces/Recipe';

interface DeleteRecipeButtonProps {
    userId: string;
    recipeId: string;
    recipes: Recipe[];
    setRecipes: React.Dispatch<React.SetStateAction<Recipe[]>>;
}

const DeleteRecipeButton: React.FC<DeleteRecipeButtonProps> = ({ userId, recipeId, recipes, setRecipes}) => {

    const deleteRecipe = (recipeId: string) => {
        const updatedRecipes = recipes.filter(recipe => recipe.recipeId !== recipeId);
        setRecipes(updatedRecipes);
    };

    const handleDelete = async () => {
        try {
            const recipeDocRef = doc(db, `users/${userId}/recipes`, recipeId);
            await deleteDoc(recipeDocRef);
            deleteRecipe(recipeId);
            console.log('Recipe successfully deleted!');
        } catch (error) {
            if (error instanceof Error) {
                console.error('Error removing recipe: ', error.message);
            } else {
                console.error('Error removing recipe');
            }
        }
    };

    return (
            <button className="btn btn-danger" onClick={handleDelete}>
                Delete Recipe
            </button>
    );
};

export default DeleteRecipeButton;