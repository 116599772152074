import React, { useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import { Spinner } from 'react-bootstrap';
import { setDoc, collection, doc, getDocs } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { db, functions } from '../Firebase/InitialiseFirebase';
import { Recipe } from '../../../interfaces/Recipe';
import {v4 as uuidv4} from 'uuid';

interface InputRecipeLinkProps {
    link: string;
}
  
const InputRecipeLink: React.FC<{ userId: string }> =
 ({ userId }) => {
    console.log("LinkInputForm")

    const [isLoading, setIsLoading] = useState(false)
    const [formValues, setFormValues] = useState<InputRecipeLinkProps>({link: '',});

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true)
        saveSummary(formValues.link, userId!);
        console.log('Link submitted:', formValues.link, userId);
    };

    const saveSummary = async (inputUrl: string, userId: string): Promise<void> => {
        try {
            //TODO this could be a function i guess...
            const scrapeAndSummariseFunction = httpsCallable(functions, 'scrape_and_summarise');
            const initialRecipe: Recipe = {
                recipeId: uuidv4(),
                title: inputUrl,
                ingredients: ["Chill your bacon", "We are racking up your recipe", "It will be here soon (5 mins)"],
                method: ["If its not", "Delete it", "And try again", "Its not rocket science", "Dont be a noob"],
                link: inputUrl,
                version: 0
              };
              const recipeRef = doc(db, `users/${userId}/recipes/${initialRecipe.recipeId}`);
              await setDoc(recipeRef, initialRecipe);
              scrapeAndSummariseFunction({ link: inputUrl, recipeId: initialRecipe.recipeId , userId: userId});
              setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            //TODO probably need an error popup
          console.error('An error occurred while calling the function: or saving', error);
        }
      };

    
    return (
<form onSubmit={handleSubmit}>
    <div className="row">
        <div className="col-md-8">
            <label htmlFor="email">Add new recipe:</label>
            <input
                className="form-control"
                type="link"
                id="link"
                name="link"
                value={formValues.link}
                onChange={handleInputChange}
            />
        </div>
        <div className="col-md-4 d-flex align-items-end">
            <Button type="submit" variant="primary" disabled={isLoading} className="w-100">
                {isLoading ? (
                    <>
                        <Spinner animation="border" size="sm" /> Loading...
                    </>
                ) : (
                    "Submit"
                )}
            </Button>
        </div>
    </div>
</form>
    );
};
export default InputRecipeLink;