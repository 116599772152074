import React, { useEffect, useState } from "react";
import { Recipe } from "../../../interfaces/Recipe";
import EditRecipe from "../Edit/EditRecipe";
import ViewRecipeButtons from "./ViewRecipeButtons";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../Firebase/InitialiseFirebase";
import { Spinner } from "react-bootstrap";
import DeleteRecipeButton from "../Delete/DeleteRecipeButton";

interface ViewRecipeProps {
    recipe: Recipe;
    setRecipe: React.Dispatch<React.SetStateAction<Recipe | null>>;
    recipes: Recipe[];
    userId: string;
    setRecipes: React.Dispatch<React.SetStateAction<Recipe[]>>;
}

const ViewRecipe: React.FC<ViewRecipeProps> = ({ recipe, setRecipe, recipes, userId, setRecipes }) => {
    console.log("ViewRecipe")
    const [isEditing, setIsEditing] = useState(false);

    const toggleEdit = () => {
        setIsEditing(!isEditing);
    };

    // useEffect(() => {
    //     const recipeRef = doc(db, `users/${userId}/recipes/${recipe.recipeId}`);

    //     const unsubscribe = onSnapshot(recipeRef, (snapshot) => {
    //         if (snapshot.exists()) {
    //             const recipeData = snapshot.data() as Recipe;
    //             const updatedRecipe = { ...recipeData, recipeId: snapshot.id };
    //             setRecipe(updatedRecipe);
    //             console.log("updatedRecipe")
    //         } else {
    //             // Handle case when the document doesn't exist
    //             console.log("Recipe not found");
    //             setRecipe(null); // or any default value
    //         }
    //     });
    //     return () => unsubscribe();
    // }, [userId, db, recipe.recipeId]);

    return (
<div className="darkBackground P10 rounded-2xl text-white align-center">
  {isEditing ? (
    <EditRecipe
      userId={userId}
      initialRecipe={recipe}
      onRecipeUpdate={setRecipe}
      recipes={recipes}
      setRecipes={setRecipes}
      toggleEdit={toggleEdit}
    />
  ) : recipe.version === 0 ? (
    <div className="d-flex flex-column align-items-center">
    <h1></h1>
    <Spinner />
    <h1></h1>
    <DeleteRecipeButton userId={userId} recipeId={recipe.recipeId} recipes={recipes} setRecipes={setRecipes} />
    <h1></h1>
    </div>
  ) : (
    <>
      <u><strong>Ingredients</strong></u>
      <ul className="align-left">
        {recipe.ingredients.map((ingredient, index) => (
          <li key={index}>{ingredient}</li>
        ))}
      </ul>
      <u><strong>Method</strong></u>
      <ol className="align-left">
        {recipe.method.map((methodStep, index) => (
          <li key={index}>{methodStep}</li>
        ))}
      </ol>
      <ViewRecipeButtons
        recipe={recipe}
        toggleEdit={toggleEdit}
        userId={userId}
        recipes={recipes}
        setRecipes={setRecipes}
      />
    </>
  )}
</div>
    );
};
export default ViewRecipe;