import React, { useState } from 'react';
import { Link } from 'react-router-dom';

type LegalEntryButtonProps = {
};

const LegalEntryButton: React.FC<LegalEntryButtonProps> = ({}) => {

  const handleClick = async () => {
  };

  return (
    <div className="text-center align-center">
      <h1></h1>
        <Link  to="/privacy-and-terms">
        <button className="btn active btn-link" >Privacy & Terms</button>
      </Link>
    </div>
  );
};

export default LegalEntryButton;