import './App.css';
import React, { useState } from 'react';

import Homepage from './components/Home/Homepage';
import PreLoginHomepage from './components/Home/PreLoginHomepage';
import LegalItems from './components/Legal/LegalItems';
import LegalEntryButton from './components/Legal/LegalEntryButton';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavBar from './components/Nav/NavBar';


export default function App() {
    const [userId, setUserId] = useState(sessionStorage.getItem('token') || null);

    const LegalPage = () => {
        return (
            <div>
                <NavBar title= "The Finer Details..." userId={userId} setUserId={setUserId} />
                <LegalItems />
            </div>
        );
    };

    const HomePage = () => {
        return (
            <div>
                <NavBar title= "Welcome to Recipe Rack - Your Ultimate Recipe Assistant! 🍳" userId={userId} setUserId={setUserId} />
                {userId ? <Homepage userId={userId} /> : null}
                {userId ? null : <PreLoginHomepage />}
                <LegalEntryButton />
            </div>
        );
    };

    return (
        <div className="darkerBackground text-white p-10 inner-container px-4 py-6">
            <Router>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/privacy-and-terms" element={<LegalPage />} />
                    {/* <Route path="/legal" component={LegalPage} /> */}
                </Routes>
            </Router>
        </div>
    );
}

