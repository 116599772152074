import React, { useState } from 'react';

type LegalItemProps = {
  title: string;
  content: string;
};

const LegalItem: React.FC<LegalItemProps> = ({ content, title }) => {
  const [context, setContext] = useState<string | null>(null);
  const [content_, setContent_] = useState<string | null>(null);

  const handleClick = async (content: string) => {
    if (content_ == content) {
      setContent_(null)
      setContext(null);
    } else {
      setContent_(content)
      setContext(content);
    }
  };
  return (
    <div>
      <button className="btn active w-100 span-button" data-bs-toggle="button"
        onClick={() => handleClick(content)}>{title}</button>
      <p>{context}</p>
    </div>
  );
};

export default LegalItem;