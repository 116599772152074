import React, { useState } from 'react';
import { Recipe } from '../../interfaces/Recipe';
import { auth, db } from '../Recipe/Firebase/InitialiseFirebase';
import { Button } from 'react-bootstrap';
import { GoogleAuthProvider, OAuthCredential, User, signInWithPopup } from 'firebase/auth';
import { Link } from 'react-router-dom';
import GoogleLogin from './GoogleLogin';
import { doc, setDoc } from 'firebase/firestore';

const provider = new GoogleAuthProvider();
// provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
// provider.setCustomParameters({
//     'login_hint': 'user@example.com'
// })

type NavBarProps = {
  title: string ; 
  userId: string | null; 
  setUserId: React.Dispatch<React.SetStateAction<string | null>>;
};

const NavBar: React.FC<NavBarProps> = ({ title, userId, setUserId }) => {
    const [recipes, setRecipes] = useState<Recipe[]>([]);

    const [userName, setUserName] = useState(sessionStorage.getItem('name') || null);
    console.log(userName)

  async function userIdPromise(): Promise<User> {
    try {
        const result = await signInWithPopup(auth, provider);
        const user = result.user;
        const name = user.displayName;
        const userId = user.uid;

        sessionStorage.setItem('token', userId);
        sessionStorage.setItem('name', name ? name : "");

        console.debug(name);

        const nameRef = doc(db, `users/${userId}`);
        await setDoc(nameRef, { name: name });

        return user;
    } catch (error) {
        console.error("Error signing in:", error);
        throw error;
    }
}

    function togglePop() {
      if (!userId) {
          userIdPromise().then((newUser) => {
              setUserId(newUser.uid)
              setUserName(newUser.displayName)
          })
      } else {
          setUserId(null)
          setUserName(null)
          sessionStorage.clear();
      }
  };

  return (
    <div>
      <nav className="darkBackground navbar rounded-2xl text-center P10">
          <div className="container-fluid">
              {/* <div className="row justify-content-center align-items-center"> */}
              <div className="row justify-content-between w-100 align-items-center">
                  <div className="col-12 col-md-3 text-center">
                  <Link  to="/">
                    <img src="/logo.png" alt="Logo" className="img-fluid" style={{ maxWidth: '180px' }} />
                    </Link>
                  </div>

                  <div className="col-12 col-md-6 text-center">
                      <h1>{title}</h1>
                  </div>

                  <div className="col-12 col-md-3 text-center">
                      <div className="px-3">{userName || ''}</div>
                      <div onClick={togglePop}>{userId ? <Button>Log out</Button> : <GoogleLogin/>}</div>
                  </div>
              </div>
          </div>
      </nav>
      <h1></h1>
    </div>
  );
  
};

export default NavBar;