import React, { useState } from "react";
import { Recipe } from "../../../interfaces/Recipe";
import { db } from "../Firebase/InitialiseFirebase";
import { doc, updateDoc } from "firebase/firestore";
import EditRecipeButtons from "./EditRecipeButtons";

interface EditRecipeProps {
    userId: string;
    initialRecipe: Recipe;
    onRecipeUpdate: (updatedRecipe: Recipe) => void;
    recipes: Recipe[];
    setRecipes: React.Dispatch<React.SetStateAction<Recipe[]>>;
    toggleEdit: () => void; 
}

const EditRecipe: React.FC<EditRecipeProps> = ({ userId, initialRecipe, onRecipeUpdate, recipes, setRecipes, toggleEdit }) => {
    const [recipe, setRecipe] = useState<Recipe>(initialRecipe);
    const arrayToText = (array: string[]) => array.join('\n')

    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRecipe({ ...recipe, title: event.target.value });
    };

    const handleIngredientsChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setRecipe({ ...recipe, ingredients: event.target.value.split('\n') });
  };

    const handleMethodChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setRecipe({ ...recipe, method: event.target.value.split('\n') });
    };

    const updateRecipe = (inputRecipe: Recipe) => {
        const oldRecipes = recipes.filter(recipe => recipe.recipeId !== inputRecipe.recipeId);
        setRecipes([...oldRecipes, inputRecipe]);
    };

    const handleSubmit = async (event: React.FormEvent) => {
      event.preventDefault();
  
      try {
          onRecipeUpdate(recipe);
          const recipeDocRef = doc(db, `users/${userId}/recipes`, recipe.recipeId);
          await updateDoc(recipeDocRef, {
            title: recipe.title,
            ingredients: recipe.ingredients,
            method: recipe.method
          });
          updateRecipe(recipe)
          toggleEdit()
          console.log('Recipe successfully updated!');
      } catch (error) {
          console.error('Error updating recipe:', error);
      }
  };

    return (
        <form onSubmit={handleSubmit} className="p-3">
            <div className="mb-3">
                <label htmlFor="title" className="form-label">Title</label>
                <input type="text" className="form-control" id="title" value={recipe.title} onChange={handleTitleChange} />
            </div>

            <div className="mb-3">
                <label htmlFor="ingredients" className="form-label">Ingredients</label>
                <textarea 
                    className="form-control" 
                    id="ingredients" 
                    rows={4} 
                    value={arrayToText(recipe.ingredients)} 
                    onChange={handleIngredientsChange} 
                />
            </div>

            <div className="mb-3">
                <label htmlFor="method" className="form-label">Method</label>
                <textarea 
                    className="form-control" 
                    id="method" 
                    rows={4} 
                    value={arrayToText(recipe.method)} 
                    onChange={handleMethodChange} 
                />
            </div>
            <EditRecipeButtons recipe={recipe} toggleEdit={toggleEdit} />
        </form>

    );
};

export default EditRecipe;
