export const firebaseConfig = {
    apiKey: "AIzaSyAsKu-EH7IynRqGMNl8sNQj1I6IR0MwQGg",
    authDomain: "sandbox-fee7a.firebaseapp.com",
    projectId: "sandbox-fee7a",
    storageBucket: "sandbox-fee7a.appspot.com",
    messagingSenderId: "166662397796",
    appId: "1:166662397796:web:da3c7629d2da8d4255a471",
    measurementId: "G-280SX18EL4"
};

