import { useEffect, useState } from "react";
import React from "react";
import ViewRecipe from "./ViewRecipe";
import { Recipe } from "../../../interfaces/Recipe";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../Firebase/InitialiseFirebase";

const ViewRecipes: React.FC<{ userId: string }> = ({ userId }) => {
    console.log("ViewRecipes");

    const [recipes, setRecipes] = useState<Recipe[]>([]);
    const [selectedRecipe, setSelectedRecipe] = useState<Recipe | null>(null);

    useEffect(() => {
      const recipeCollectionRef = collection(db, `users/${userId}/recipes`);

      const unsubscribe = onSnapshot(recipeCollectionRef, (snapshot) => {
          const newData: Recipe[] = [];
          snapshot.forEach((doc) => {
              const recipeData = doc.data() as Recipe;
              newData.push({ ...recipeData, recipeId: doc.id });
          });
          setRecipes(newData);
          console.log("new recipe from subscription")
      });

      return () => unsubscribe();
  }, [userId, db]);

    const handleRecipeClick = (recipe: Recipe) => {
        if (selectedRecipe?.recipeId == recipe.recipeId)  {
            console.log("Clearing Selected Recipe : " + recipe.recipeId)
            setSelectedRecipe(null)
        } else {
            console.log("Selected Recipe : " + recipe.recipeId)
            setSelectedRecipe(recipe)
        }
    };

    return (
        <div className="darkBackground rounded-2xl text-white">
            {recipes.map((recipe) => (
                <div key={recipe.recipeId} className="align-center">
                    <div className="darkBackground rounded-2xl text-white" key={recipe.recipeId}>
                        <button className="btn active w-100 span-button" data-bs-toggle="button"

                        onClick={() => handleRecipeClick(recipe)}>{recipe.title}</button>

                        {selectedRecipe && selectedRecipe.recipeId === recipe.recipeId && (
                            <div className="recipe-details">
                                <p></p>   
                                <ViewRecipe recipe={recipe} recipes={recipes} userId={userId} setRecipes={setRecipes}
                                 setRecipe={setSelectedRecipe} />
                                 {/* TODO review the inputs in here, not sure we need all of them */}
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </div>

    );
}
export default ViewRecipes;
