import React, { useEffect, useState } from 'react';
import LinkInputForm from '../Recipe/Input/InputRecipeLink'; // Adjust the path as per your file structure
import ViewRecipes from '../Recipe/View/ViewRecipes';
import { Recipe } from '../../interfaces/Recipe';
import { collection, getDocs, onSnapshot } from 'firebase/firestore';
import { db } from '../Recipe/Firebase/InitialiseFirebase';

type HomepageProps = {
  userId: string ; 
};

const Homepage: React.FC<HomepageProps> = ({ userId }) => {
  return (
    <div>
      <LinkInputForm userId={userId} />
      <h1></h1>
      <ViewRecipes userId={userId} />
    </div>
  );
  
};

export default Homepage;