import React, { useState } from "react";
import { Recipe } from "../../../interfaces/Recipe";

interface EditRecipeButtonsProps {
    recipe: Recipe;
    toggleEdit: () => void;
}

const EditRecipeButtons: React.FC<EditRecipeButtonsProps> = ({ recipe, toggleEdit }) => {
    return (
        <div className="d-flex justify-content-between align-items-center p-2">
            <div className="col-4 d-flex justify-content-center">
                <button className="btn btn-danger mr-1" onClick={toggleEdit}>Cancel</button>
            </div>

            <div className="col-4 d-flex justify-content-center">
                <a href={recipe.link} target="_blank" rel="noopener noreferrer" className="btn btn-primary mr-1">Source</a>
            </div>

            <div className="col-4 d-flex justify-content-center">
                {/* <button className="btn btn-success mr-1" onClick={toggleEdit}>Save</button> */}
                <button type="submit" className="btn btn-success">Save</button>
            </div>
        </div>
    );
};

export default EditRecipeButtons;
