import { FirebaseApp, initializeApp } from 'firebase/app';
import { Auth, getAuth, connectAuthEmulator } from 'firebase/auth';
import 'firebase/compat/firestore';
import { Firestore, getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { Functions, getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { firebaseConfig } from '../../../config/FirebaseConfig';

export const app: FirebaseApp = initializeApp(firebaseConfig);
export const db: Firestore = getFirestore(app);
export const functions: Functions = getFunctions(app);
export const auth: Auth = getAuth(app);

if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" || process.env.NODE_ENV === 'development') {
    console.log("Running locally");
    connectFirestoreEmulator(db, 'localhost', 8080);
    connectFunctionsEmulator(functions, 'localhost', 5001);
    connectAuthEmulator(auth, 'http://localhost:9099');
} else {
    console.log("Running in production Firebase Hosting environment");
}

console.log('Firebase Initialized');
