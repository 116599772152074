import React, {} from 'react';


type PreLoginHomepageProps = {
};

const PreLoginHomepage: React.FC<PreLoginHomepageProps> = ({}) => {    
  return (
    <div className="darkBackground rounded-2xl text-white align-center">
    <p>We're delighted to have you on Recipe Rack, your trusted partner for simplifying recipe discovery and enhancing your cooking journey. To get started and unlock all the fantastic features we offer, please follow this quick guide:</p>

    <ul className="list-unstyled">
        <li>Use Recipe Rack to effortlessly extract recipes from your favorite websites. 🔍</li>
        <li>Start by logging in to access our AI-powered recipe summarization feature. 🔑</li>
    </ul>

    <h2>Summarized Recipe Format 📃</h2>
    <p>Once logged in, our powerful AI will instantly analyze recipes and convert them into a standardized format. You'll receive clear and concise recipe summaries with detailed ingredient lists and instructions.</p>

    <h2>Why Choose Recipe Rack? 🌟</h2>

    <ul className="list-unstyled">
        <li>Save Time: Our AI-driven summarization saves you from reading lengthy recipe pages. ⏰</li>
        <li>Organized Cooking: Access standardized recipes for easy understanding. 📋</li>
        <li>Recipe Library: Build your collection of beloved recipes with the save feature. 📚</li>
    </ul>

    <h2>Get Started with Recipe Rack 👉</h2>
    <p>Begin your culinary adventure by logging in to Recipe Rack. Once logged in, you can paste a recipe link, click "Submit," and begin.</p>

    <p>Should you have any questions or require assistance with your login, our support team is ready to assist you. Happy cooking! 🥘🍽️</p>
    </div>
  );
  
};

export default PreLoginHomepage;