import React, { useState } from "react";
import { Recipe } from "../../../interfaces/Recipe";
import DeleteRecipeButton from "../Delete/DeleteRecipeButton";

interface EditRecipeButtonsProps {
    recipe: Recipe;
    toggleEdit: () => void; 
    userId: string;
    recipes: Recipe[];
    setRecipes: React.Dispatch<React.SetStateAction<Recipe[]>>;
}

const EditRecipeButtons: React.FC<EditRecipeButtonsProps> = ({ recipe, toggleEdit, userId, recipes, setRecipes}) => {
    return (
        <div className="d-flex justify-content-between align-items-center p-2">
        <div className="col-4 d-flex justify-content-center">
        <DeleteRecipeButton userId={userId} recipeId={recipe.recipeId} recipes={recipes} setRecipes={setRecipes} />
        </div>

        <div className="col-4 d-flex justify-content-center">
            <a href={recipe.link} target="_blank" rel="noopener noreferrer" className="btn btn-primary mr-1">Source</a>
        </div>

        <div className="col-4 d-flex justify-content-center">
            <button className="btn btn-secondary mr-1" onClick={toggleEdit}>Edit</button>
        </div>
    </div>
    );
};

export default EditRecipeButtons;
