
import React, { useEffect, useState } from 'react';
import LegalItem from './LegalItem';
import LegalDocumentation from './LegalDocumentation';

type LegalItemsProps = {
};

const LegalItems: React.FC<LegalItemsProps> = ({ }) => {
    return (
        <div>
            <LegalItem content={LegalDocumentation.cookies} title="Cookies"></LegalItem>
            <LegalItem content={LegalDocumentation.disclaimer} title="Disclaimer"></LegalItem>
            <LegalItem content={LegalDocumentation.termsAndConditions} title="Terms and Conditions"></LegalItem>
            <LegalItem content={LegalDocumentation.privacy} title="Privacy Policy"></LegalItem>
            <LegalItem content={LegalDocumentation.acceptableUse}  title="Acceptable Use"></LegalItem>
        </div>
    );
};

export default LegalItems;